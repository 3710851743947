﻿

.add-shadow {
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.add-shadow-darker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 0  20px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.3) !important;
}

