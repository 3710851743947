﻿
$maroon: rgb(90 12 45);
$beige : rgb(217 201 174);
$off-white :  rgb(244 242 237);
$maroon-active:  rgba(90, 12, 45, 0.4);

:export {
  maroon: $maroon;
  beige: $beige;
  off-white: $off-white;
  maroon-active: $maroon-active;
}



