﻿
@mixin linear-gradient-transition-before($degree, $r, $g, $b,$end:  10%  ,$start: 0 ){
  position: relative;

  &:before{
    content: '';
    @include pos-abs;
    background: rgba($r,$g,$b, 0);
    background: linear-gradient($degree, rgba($r,$g,$b,.2) $start, rgba($r,$g,$b,0) $end);
    z-index: -1;
  }

}

@mixin linear-gradient-transition-after($degree, $r, $g, $b,$end: 10% ,$start: 0 ){
  position: relative;

  &:after{
    content: '';
    @include pos-abs;
    background: rgba($r,$g,$b, 0);
    background: linear-gradient($degree, rgba($r,$g,$b,.2) $start, rgba($r,$g,$b,0) $end);
  z-index: -1;
  }
}

@mixin pos-abs($top: 0, $right: 0, $bottom: 0, $left: 0) {
  position: absolute;
  top: $top;
  right: $right;
  left: $left;
  bottom: $bottom;
}

@mixin red_green_strips($opacity: 1){
  background: repeating-linear-gradient(
                  -45deg,
                  rgba($success-color, $opacity) 0px,
                  rgba($success-color, $opacity)  25px,
                  rgba(0,0,0,0) 25px,
                  rgba(0,0,0,0) 50px,
                  rgba($primary-color, $opacity) 50px,
                  rgba($primary-color, $opacity) 75px,
                  rgba(0,0,0,0) 75px,
                  rgba(0,0,0,0) 100px
  ) !important;
}

@mixin red_white_green_strips($opacity: 1){
  background: repeating-linear-gradient(
                  -45deg,
                  rgba($success-color, $opacity) 0px,
                  rgba($success-color, $opacity)  5px,
                  rgba(255,255,255,1) 5px,
                  rgba(255,255,255,1) 10px,
                  rgba($primary-color, $opacity) 10px,
                  rgba($primary-color, $opacity) 15px,
                  rgba(255,255,255,1) 15px,
                  rgba(255,255,255,1) 20px
  ) !important;
}

@mixin stamp-effect-color($color: white){
  
  --radius: 8px;
  background-image: radial-gradient(
                  var(--radius),
                  transparent 98%,
                  $color
  ),
  linear-gradient($color 0 0);
  background-repeat: round,no-repeat;
  background-position: calc(var(--radius) * -1.5) calc(var(--radius) * -1.5), 50%;
  background-size: calc(var(--radius) * 3) calc(var(--radius) * 3),
  calc(100% - var(--radius) * 3) calc(100% - var(--radius) * 3);

  
}

@mixin stamp-effect-color-shadow ($color: white){
  position: relative !important;
  @include  stamp-effect-color($color);
  z-index: 10;

  
}
