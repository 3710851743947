﻿$mobile: 479;
$tablet: 768;
$tablets-below: 980;
$small: 981;
$medium: 1024;
$large: 1405;


$size__site_content_width: 1024px;

$media_queries : (
        'mobile'        : "screen and (max-width: " + $mobile + "px )",
        'mobile-only'   : "screen and (max-width: " + ( $tablet - 1 ) + "px )",
        'tablet'        : "screen and (max-width: " + $tablets-below + "px )",
        'small'         : "screen and (min-width: " + $small + "px ) and (max-width: " + ( $medium - 1 ) + "px )",
        'medium'        : "screen and (min-width: " + $medium + "px ) and (max-width: " + ($large - 1 ) + "px )",
        'large'         : "screen and (min-width: " + $large + "px )",
        'landscape'     : "screen and (orientation:landscape) ",
        'portrait'      : "screen and (orientation:portrait) "

);

@mixin for_breakpoint($breakpoints) {
  $conditions : ();
  @each $breakpoint in $breakpoints {
    // If the key exists in the map
    $conditions:  append(
                    $conditions,
                    #{map-get($media_queries, $breakpoint)},
                    comma
    );
  }

  @media #{$conditions} {
    @content; 
  }
} 

body{
  width: 100vw;
  height: 100vw;
  max-width: 100%;
  padding: 0;
  margin: 0;
}