﻿
@use "../core/mixins";
.max-container-width {
  max-width: 1200px;
  margin-left:auto;
  margin-right: auto;
  padding:1em;
  
  
   @include for_breakpoint(mobile-only){
     padding: 0;
   }
}
.banner-text-control {
  font-size: clamp(2rem, 2.231rem + 3.077vi, 3rem);
  text-align: center;
  //color: white;
  font-family: MonteCarlo;
  
  &.bigger {
    font-size: clamp(2.5rem, 2.231rem + 3.077vi, 3.5rem);
  }
}
//#tabCont1 {
//  height: calc(100vw * .62);
//  max-height: 650px;
//  
//  @include for_breakpoint(mobile-only){
//    height: unset;
//    max-height: unset;
//  }
//}

#tabbed-plans {
 
  display: flex;
  justify-content: center;
  &__container{
    display: flex;
    flex-direction: column;
   
    & > #tab-container {
     
      & > .size-tabs {
        
      }
    }
    #plans-containers{
     
    }
  }

}
.tabs-div__container {
   display: flex;
 
  padding: 2rem;
    height: calc(100vw * .62);
    max-height: 750px;

    @include for_breakpoint(mobile-only){
      height: unset;
      
    }
 

  @include for_breakpoint(mobile-only   ){
    padding: 1rem;
    justify-content: space-evenly;
  }
  
  @include for_breakpoint(mobile-only ){
    flex-direction: column;
  }
  
  &.image{
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
    & > img {
      object-fit: contain;
      //max-width: 500px;
      overflow: hidden;
      align-self: center;
    }

    @include for_breakpoint(mobile-only tablet ){
      padding: 0rem !important;
    }
    
    
   
  }
  
  
  
  &.text{
   
    display: flex;
    flex-direction: column;
    align-items: unset !important;
   
    width:100%;
   
    

    @include for_breakpoint(mobile-only ){
   
     align-self: center;
     
      justify-content: space-between;
    }
    
   
    font-weight: bold;
    & > h1 {

      margin: 1rem;
      text-align: left;
     
      @include for_breakpoint(mobile-only ){
        font-size: 3rem;
        text-align: center;
      }
      
      @include for_breakpoint(tablet small medium large){
       
      }
      
    }
    & > div {
      margin: 1rem;
      padding: 1rem 1rem 1rem 2rem;
      font-weight: normal;

      @include for_breakpoint(mobile-only tablet ){
        padding: 0;
        width: 100%;

      }
      @include for_breakpoint(mobile-only ){
      
       justify-content: space-around;

      }

    }
  }

  &.image-singles{
    width: 100%;
    display: flex;
    justify-content: center;
   
   
    & > img {
      object-fit: contain;
      //max-width: 500px;
      overflow: hidden;
      
     
    }

    @include for_breakpoint(mobile-only tablet ){
      padding: 1rem !important;
    }



  }
}  

.gradient1 {
  mix-blend-mode: darken;
  
  @include mixins.pos-abs();
  @include mixins.linear-gradient-transition-before(90deg, 0,0,0, 300px, 30px);
  @include mixins.linear-gradient-transition-after(-90deg,0,0,0, 300px, 30px);
  
}

.tabs__text-box {
  
  margin-top:2em;  

  @include for_breakpoint(mobile-only){
      display: flex;
      
      
  }
  @include for_breakpoint(mobile){
      flex-direction: column;
  }

  @include for_breakpoint(tablet){
  
    margin-top:1em;
  }
  
  @include for_breakpoint(tablet small medium large ){
     font-size: 1.2em;
  }
  
  & > ul > li:before {
    content: "\2022";
    padding-right: .5em;
    opacity: .5;
  }
  
 
}

#top-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(217 201 174);
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  overflow: hidden;


  @include for_breakpoint(mobile-only) {
    display: none;
    width: calc (100% / 3 * 2);
    
    &.show{
      display:flex;
    }

  }

  

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row;
    //justify-content: center;
    justify-content: space-evenly;
    font-family: Charm, serif;
    font-weight: bolder;
    text-align: left;
    align-items: center;
    //height: 100%;
    
    &.height100 {
      height: 100%;
    }
    
    
    & > .top-tabs__btn {
      height: 100%;
      padding: 0.75rem;
      align-content: center;
      width: 100%;
      text-align: center;
      font-size: 1.3em;
      user-select: none;
      //height: 100%;
      align-items: center;
      color: rgb(90 12 45);
      cursor: pointer;
      transition: all .25s ease-in-out;
      &.height100 {
        height: 100%;
      }
  
      &.active, &:hover {
       
        align-content: center;
        background-color: #ede7de;
        color: rgb(90 12 45);
      
      }
    }
    
    
    
  }

}

.video {
  max-width: 1000px;
  width: 100%;
  height: calc(100vw * .545);
  //max-height: 400px;
  
  @include for_breakpoint(small medium large){
   
    height: 563px;
   
  }
  
  & > iframe {
    object-fit: contain;
    width: 100%;
  }
  
}

.donation-box {
   margin-bottom : 2rem;
  max-width: 1150px;
  width: 100%;
  padding: 5rem;
  paddingTop: 0;
  
  & .rows {
    gap: 2rem;

    @include for_breakpoint(mobile-only){
      gap: 0rem;
    }
  }
  
  @include for_breakpoint(tablet){
    padding: 1rem;
  }
  
  &.title{
    margin-bottom : 0rem;
    padding: 2rem;
  }
  
  &.tile{
    padding: 2rem;
  background-color: #e1d8c8;
    border-radius: 1rem;
  @extend .add-shadow;
  }
  
  &-container {
    display: flex;
    justify-content: center  ;
    //background-color: #e1d8c8;
    z-index: +1;
    width: 100%;
  }
    
}

.picture-back{
  --tw-bg-opacity: 1;
  background-color: rgb(217 201 174 / var(--tw-bg-opacity));
  @include pos-abs;
  //z-index: -1;
  
  width: 100%;
  
  
  
  &:after {
   content: "";
 
    @include pos-abs();
    opacity: 1;
    background:  20% / cover fixed no-repeat url("/public/dist/img/slide_12.jpg");
    left: 0;
    
    
    //z-index: -1;
    
  }

  &:before{

    position: fixed;
    top:0;

    left:0;
    width: 50%;
    bottom: 0;
    mix-blend-mode: multiply;
    background: rgb(0,0,0);
    background: radial-gradient(circle at 50% 250%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.4) 100%);

  }

  
  
}
  .vignette-box {
    position: absolute;
    top:0;
  
    left:0;
    right: 0;
    bottom: 0;
    
      & .vignette{
      
        position: fixed;
        top:0;
      
        left:0;
        width: 50%;
        bottom: 0;
        mix-blend-mode: multiply;
        background: rgb(0,0,0);
        background: radial-gradient(circle at 50% 250%, rgba(0,0,0,0) 40%, rgba(0,0,0,0.4) 100%);
      
      }
    
  }

.footer-text{
  display: flex;
  flex-direction: row;
  gap: 1rem;
  
  @include for_breakpoint(mobile-only){
    flex-direction: column;
    gap: 1em;
    padding: 1rem;
  }
}

.hero-image{
  bottom: -35%;
  transition: all .25s ease;
  
  
  @include for_breakpoint(mobile tablet small medium){
    bottom: 0;
  }
  
  @include for_breakpoint(large){
    bottom: -15%;
  }
}

.contact-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1150px;
  gap: 2rem;
  
  @include for_breakpoint(tablet){
    flex-direction: column;
    justify-content: center;
    gap:0;
    
    & > div {
      max-width: 500px;
      align-self: center;
    }
  }
  
  &__container {
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    max-width: 100%;
    @include for_breakpoint(tablet){
     
    }
  }
  
}

.mapHeight {
  width: 100%;
  @include for_breakpoint(tablet){
    min-height: 400px;
    min-width: 400px;
  }
}
.headerText {
  font-size: clamp(1.9rem, -1.878rem + 5.505vw, 2.25rem);

  line-height: 2.5rem;
  
  @include for_breakpoint(tablet ){
    font-size: clamp(1rem, 1.7rem + 0.8vw, 2.25rem);

    line-height: 2rem;
  }
  
}


#menu {
  font-family: Charm;
  --tw-bg-opacity: 1;
  background-color: $off-white;
  color:$maroon;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-around;
  text-align: left;
  
  @include for_breakpoint(medium large){
    display: flex;

    & > .menuBtn, & > .menuBtn__container {
      
      &.hiddable {
        display: none;
      }
      
      padding: .75rem 0;
      
      &:hover, & > a:hover, & > div > a:hover   {
        background-color: $maroon;
        color: $off-white;
      }
      
      & > .menu-active, & > div > .menu-active {
        
        background-color: $maroon-active !important  ;
        color: $off-white;
       
      }
      
      & > a, & > div > a {
        padding: 10px 12px;
        color:$maroon;
      }
      
      &__container {
        padding: .75rem .75rem;
      }
    }
  }
  


  @include for_breakpoint(mobel-only tablet small ){
    text-align: right;
    position: absolute;
    flex-direction: column;
    top: 0;
    margin-top: 2.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(244 242 237 / var(--tw-bg-opacity));
    
    & > li {
      
      padding: 0;

      &:hover {
          background-color: $maroon;
          color: $off-white;
      }
      
      & > a {
        display: block !important;
        padding: .75rem;
      }
      
      & > .menu-active{
        display: block !important;
        background-color: $maroon-active !important  ;
        color: $off-white;
      }
    }
    & > .menuBtn__container {
      display: none;
    }
  }

}

.cartmell-lake-link {
  
}

