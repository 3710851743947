@charset "UTF-8";
:export {
  maroon: rgb(90, 12, 45);
  beige: rgb(217, 201, 174);
  off-white: rgb(244, 242, 237);
  maroon-active: rgba(90, 12, 45, 0.4);
}

body {
  width: 100vw;
  height: 100vw;
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.max-container-width {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}
@media screen and (max-width: 767px ) {
  .max-container-width {
    padding: 0;
  }
}

.banner-text-control {
  font-size: clamp(2rem, 2.231rem + 3.077vi, 3rem);
  text-align: center;
  font-family: MonteCarlo;
}
.banner-text-control.bigger {
  font-size: clamp(2.5rem, 2.231rem + 3.077vi, 3.5rem);
}

#tabbed-plans {
  display: flex;
  justify-content: center;
}
#tabbed-plans__container {
  display: flex;
  flex-direction: column;
}
.tabs-div__container {
  display: flex;
  padding: 2rem;
  height: 62vw;
  max-height: 750px;
}
@media screen and (max-width: 767px ) {
  .tabs-div__container {
    height: unset;
  }
}
@media screen and (max-width: 767px ) {
  .tabs-div__container {
    padding: 1rem;
    justify-content: space-evenly;
  }
}
@media screen and (max-width: 767px ) {
  .tabs-div__container {
    flex-direction: column;
  }
}
.tabs-div__container.image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.tabs-div__container.image > img {
  object-fit: contain;
  overflow: hidden;
  align-self: center;
}
@media screen and (max-width: 767px ), screen and (max-width: 980px ) {
  .tabs-div__container.image {
    padding: 0rem !important;
  }
}
.tabs-div__container.text {
  display: flex;
  flex-direction: column;
  align-items: unset !important;
  width: 100%;
  font-weight: bold;
}
@media screen and (max-width: 767px ) {
  .tabs-div__container.text {
    align-self: center;
    justify-content: space-between;
  }
}
.tabs-div__container.text > h1 {
  margin: 1rem;
  text-align: left;
}
@media screen and (max-width: 767px ) {
  .tabs-div__container.text > h1 {
    font-size: 3rem;
    text-align: center;
  }
}
.tabs-div__container.text > div {
  margin: 1rem;
  padding: 1rem 1rem 1rem 2rem;
  font-weight: normal;
}
@media screen and (max-width: 767px ), screen and (max-width: 980px ) {
  .tabs-div__container.text > div {
    padding: 0;
    width: 100%;
  }
}
@media screen and (max-width: 767px ) {
  .tabs-div__container.text > div {
    justify-content: space-around;
  }
}
.tabs-div__container.image-singles {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tabs-div__container.image-singles > img {
  object-fit: contain;
  overflow: hidden;
}
@media screen and (max-width: 767px ), screen and (max-width: 980px ) {
  .tabs-div__container.image-singles {
    padding: 1rem !important;
  }
}

.gradient1 {
  mix-blend-mode: darken;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  position: relative;
  position: relative;
}
.gradient1:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 30px, rgba(0, 0, 0, 0) 300px);
  z-index: -1;
}
.gradient1:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(-90deg, rgba(0, 0, 0, 0.2) 30px, rgba(0, 0, 0, 0) 300px);
  z-index: -1;
}

.tabs__text-box {
  margin-top: 2em;
}
@media screen and (max-width: 767px ) {
  .tabs__text-box {
    display: flex;
  }
}
@media screen and (max-width: 479px ) {
  .tabs__text-box {
    flex-direction: column;
  }
}
@media screen and (max-width: 980px ) {
  .tabs__text-box {
    margin-top: 1em;
  }
}
@media screen and (max-width: 980px ), screen and (min-width: 981px ) and (max-width: 1023px ), screen and (min-width: 1024px ) and (max-width: 1404px ), screen and (min-width: 1405px ) {
  .tabs__text-box {
    font-size: 1.2em;
  }
}
.tabs__text-box > ul > li:before {
  content: "•";
  padding-right: 0.5em;
  opacity: 0.5;
}

#top-tabs {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(217, 201, 174);
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  overflow: hidden;
}
@media screen and (max-width: 767px ) {
  #top-tabs {
    display: none;
    width: calc 66.6666666667%;
  }
  #top-tabs.show {
    display: flex;
  }
}
#top-tabs__container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-family: Charm, serif;
  font-weight: bolder;
  text-align: left;
  align-items: center;
}
#top-tabs__container.height100 {
  height: 100%;
}
#top-tabs__container > .top-tabs__btn {
  height: 100%;
  padding: 0.75rem;
  align-content: center;
  width: 100%;
  text-align: center;
  font-size: 1.3em;
  user-select: none;
  align-items: center;
  color: rgb(90, 12, 45);
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
#top-tabs__container > .top-tabs__btn.height100 {
  height: 100%;
}
#top-tabs__container > .top-tabs__btn.active, #top-tabs__container > .top-tabs__btn:hover {
  align-content: center;
  background-color: #ede7de;
  color: rgb(90, 12, 45);
}

.video {
  max-width: 1000px;
  width: 100%;
  height: 54.5vw;
}
@media screen and (min-width: 981px ) and (max-width: 1023px ), screen and (min-width: 1024px ) and (max-width: 1404px ), screen and (min-width: 1405px ) {
  .video {
    height: 563px;
  }
}
.video > iframe {
  object-fit: contain;
  width: 100%;
}

.donation-box {
  margin-bottom: 2rem;
  max-width: 1150px;
  width: 100%;
  padding: 5rem;
  paddingTop: 0;
}
.donation-box .rows {
  gap: 2rem;
}
@media screen and (max-width: 767px ) {
  .donation-box .rows {
    gap: 0rem;
  }
}
@media screen and (max-width: 980px ) {
  .donation-box {
    padding: 1rem;
  }
}
.donation-box.title {
  margin-bottom: 0rem;
  padding: 2rem;
}
.donation-box.tile {
  padding: 2rem;
  background-color: #e1d8c8;
  border-radius: 1rem;
}
.donation-box-container {
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 100%;
}

.picture-back {
  --tw-bg-opacity: 1;
  background-color: rgb(217 201 174/var(--tw-bg-opacity));
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
.picture-back:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 1;
  background: 20%/cover fixed no-repeat url("/public/dist/img/slide_12.jpg");
  left: 0;
}
.picture-back:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  bottom: 0;
  mix-blend-mode: multiply;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle at 50% 250%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%);
}

.vignette-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.vignette-box .vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 50%;
  bottom: 0;
  mix-blend-mode: multiply;
  background: rgb(0, 0, 0);
  background: radial-gradient(circle at 50% 250%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%);
}

.footer-text {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
@media screen and (max-width: 767px ) {
  .footer-text {
    flex-direction: column;
    gap: 1em;
    padding: 1rem;
  }
}

.hero-image {
  bottom: -35%;
  transition: all 0.25s ease;
}
@media screen and (max-width: 479px ), screen and (max-width: 980px ), screen and (min-width: 981px ) and (max-width: 1023px ), screen and (min-width: 1024px ) and (max-width: 1404px ) {
  .hero-image {
    bottom: 0;
  }
}
@media screen and (min-width: 1405px ) {
  .hero-image {
    bottom: -15%;
  }
}

.contact-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1150px;
  gap: 2rem;
}
@media screen and (max-width: 980px ) {
  .contact-map {
    flex-direction: column;
    justify-content: center;
    gap: 0;
  }
  .contact-map > div {
    max-width: 500px;
    align-self: center;
  }
}
.contact-map__container {
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
}
.mapHeight {
  width: 100%;
}
@media screen and (max-width: 980px ) {
  .mapHeight {
    min-height: 400px;
    min-width: 400px;
  }
}

.headerText {
  font-size: clamp(1.9rem, -1.878rem + 5.505vw, 2.25rem);
  line-height: 2.5rem;
}
@media screen and (max-width: 980px ) {
  .headerText {
    font-size: clamp(1rem, 1.7rem + 0.8vw, 2.25rem);
    line-height: 2rem;
  }
}

#menu {
  font-family: Charm;
  --tw-bg-opacity: 1;
  background-color: rgb(244, 242, 237);
  color: rgb(90, 12, 45);
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-around;
  text-align: left;
}
@media screen and (min-width: 1024px ) and (max-width: 1404px ), screen and (min-width: 1405px ) {
  #menu {
    display: flex;
  }
  #menu > .menuBtn, #menu > .menuBtn__container {
    padding: 0.75rem 0;
  }
  #menu > .menuBtn.hiddable, #menu > .menuBtn__container.hiddable {
    display: none;
  }
  #menu > .menuBtn:hover, #menu > .menuBtn > a:hover, #menu > .menuBtn > div > a:hover, #menu > .menuBtn__container:hover, #menu > .menuBtn__container > a:hover, #menu > .menuBtn__container > div > a:hover {
    background-color: rgb(90, 12, 45);
    color: rgb(244, 242, 237);
  }
  #menu > .menuBtn > .menu-active, #menu > .menuBtn > div > .menu-active, #menu > .menuBtn__container > .menu-active, #menu > .menuBtn__container > div > .menu-active {
    background-color: rgba(90, 12, 45, 0.4) !important;
    color: rgb(244, 242, 237);
  }
  #menu > .menuBtn > a, #menu > .menuBtn > div > a, #menu > .menuBtn__container > a, #menu > .menuBtn__container > div > a {
    padding: 10px 12px;
    color: rgb(90, 12, 45);
  }
  #menu > .menuBtn__container, #menu > .menuBtn__container__container {
    padding: 0.75rem 0.75rem;
  }
}
@media screen and (max-width: 980px ), screen and (min-width: 981px ) and (max-width: 1023px ) {
  #menu {
    text-align: right;
    position: absolute;
    flex-direction: column;
    top: 0;
    margin-top: 2.5rem;
    --tw-bg-opacity: 1;
    background-color: rgb(244 242 237/var(--tw-bg-opacity));
  }
  #menu > li {
    padding: 0;
  }
  #menu > li:hover {
    background-color: rgb(90, 12, 45);
    color: rgb(244, 242, 237);
  }
  #menu > li > a {
    display: block !important;
    padding: 0.75rem;
  }
  #menu > li > .menu-active {
    display: block !important;
    background-color: rgba(90, 12, 45, 0.4) !important;
    color: rgb(244, 242, 237);
  }
  #menu > .menuBtn__container {
    display: none;
  }
}

#modal {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  opacity: 0;
  transition: opacity 0.25s ease;
}
#modal.show {
  display: flex;
  justify-content: center;
  align-items: center;
}
#modal .background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
#modal .close {
  position: absolute;
  right: 40px;
  top: 20px;
  color: white;
  cursor: pointer;
  opacity: 0.7;
  border: 2px solid white;
  height: 40px;
  border-radius: 50%;
  width: 40px;
  transition: all 0.25s ease;
}
#modal .close:hover {
  background-color: white;
}
#modal .close:hover:before, #modal .close:hover:after {
  background-color: black;
}
#modal .close:before, #modal .close:after {
  position: absolute;
  left: 16.5px;
  top: 5px;
  content: " ";
  height: 27px;
  width: 3px;
  background-color: white;
}
#modal .close:before {
  transform: rotate(45deg);
}
#modal .close:after {
  transform: rotate(-45deg);
}
#modal .image {
  background-color: whitesmoke;
  width: 500px;
  height: 80%;
}

.add-shadow, .donation-box.tile {
  box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.05), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.add-shadow-darker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05), 0 6px 12px 0 rgba(0, 0, 0, 0.3) !important;
}

