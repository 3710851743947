﻿
#modal{
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 100000;
  opacity: 0;
  transition: opacity 0.25s ease;
  
  
  &.show {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  & .background {
   @include pos-abs;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: -1;
  }
  
  & .close {
    position: absolute;
    right: 40px;
    top: 20px;
    color: white;
    cursor: pointer;
  
    opacity: 0.7;

    border: 2px solid white;
    
    height:40px;
    border-radius:50%;
  
    width:40px;
    transition: all .25s ease;
    &:hover{
      background-color: white;

      &:before, &:after {
        background-color: black;
      }
    }
    
    &:before, &:after {
      position: absolute;
      left: 16.5px;
      top:5px;
      content: ' ';
      height: 27px;
      width: 3px;
      background-color: white;
     
    }
    
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    
   
  }
  //& .circle {
  //  position: absolute;
  //  border: 2px solid white;
  //  right: 24.7%;
  //  top: 27px;
  //
  //  height:40px;
  //  border-radius:50%;
  //  -moz-border-radius:50%;
  //  -webkit-border-radius:50%;
  //  width:40px;
  //  &:hover {
  //    background-color: white;
  //    
  //  }
  //}
  
  & .image {
    background-color: whitesmoke;
    width: 500px;
    height: 80%;
  }
}